<template>
  <div>
    <v-sheet tile class="charcoal paper--text ">
      <v-card-actions class="pr-0 ">
        <span :class="textSize">
          {{ exercise.name }}
        </span>
        <v-spacer />
        <v-icon
          large
          :title="'Dificulty: ' + exercise.dificulty"
          :color="dificultyColor(exercise.dificulty)"
        >
          mdi-checkbox-blank
        </v-icon>
      </v-card-actions>
    </v-sheet>
    <v-card class="py-0 px-0 transparent" flat tile v-if="loaded">
      <v-sheet rounded class="mx-2 pt-2 mb-2 transparent charcoalTileMenu">
        <v-card-actions class="py-1 pt-0 pl-0">
          <template v-if="loggedIn">
            <v-icon
              v-if="isFav"
              color="accent"
              large
              class=""
              @click="removeFavorite(exercise.id)"
            >
              mdi-heart
            </v-icon>
            <v-icon
              v-else
              color="accent"
              large
              class=""
              @click="addFavorite(exercise.id)"
            >
              mdi-heart-outline
            </v-icon>
          </template>
          <v-spacer />
          <span :class="textSizeXSmall">
            <span :class="dificultyColor(exercise.dificulty, true)">
              {{ exercise.dificulty.toUpperCase() }}
            </span>
          </span>
        </v-card-actions>
        <v-sheet
          color="transparent charcoalTile"
          class="ma-2 py-2"
          rounded
          v-if="topLifts.length > 0"
        >
          <v-card-title :class="textSize + ' pb-0 px-0 paper--text'">
            <span class="mt-n5">
              Top Lifters
            </span>
            <v-spacer />
            <v-switch
              dark
              color="progressActive"
              class="pa-0 ma-0"
              v-model="showTopLifters"
            />
          </v-card-title>
          <template v-if="showTopLifters">
            <v-card-actions
              v-for="(ex, index) in topLifts"
              :key="index"
              :class="textSizeSmall + ' silver--text  ml- pa-0'"
            >
              <BaseUserProfile :item="ex" :key="ex.id" />
              <v-spacer />
              {{ Math.round(ex.imperial) }} lbs / {{ Math.round(ex.metric) }} kg
              / {{ ex.bwratio }} SELFS
            </v-card-actions>
          </template>
        </v-sheet>
        <v-sheet color="transparent charcoalTile" class="ma-2 py-2" rounded>
          <template v-if="loggedIn && exercise.maxtest == 'Yes' && rm != null">
            <v-card-text class="silver--text py-0 px-2">
              <span :class="textSize">
                Current 1RM:
                <span class="progressActive--text">
                  {{ rm.max_imperial }} lbs / {{ rm.max_metric }} kg
                </span>
                <v-spacer />
              </span>
            </v-card-text>
            <v-card-actions class="caption silver--text pa-0 ml-2 mt-n1">
              Last Tested: {{ prettyDateTime(rm.created_local) }}
            </v-card-actions>
          </template>
          <v-card-actions
            v-if="loggedIn && exercise.maxtest == 'Yes'"
            class="pl-0 pb-0"
          >
            <BaseActionButton
              icon="mdi-cryengine"
              :label="historyLabel"
              color="silver"
              :title="'Exercise History For: ' + exercise.name"
              text
              @clickedThis="$router.push('/strengthdetail/' + exercise.id)"
            />
            <v-spacer />
            <v-btn
              title="Add 1RM (One Repetition Maximum)"
              @click="$emit('repmax', exercise)"
              text
              class="paper--text"
              :large="!isPhone"
            >
              <v-icon class="mr-2" large @click="$emit('repmax', exercise)">
                mdi-plus-circle-outline
              </v-icon>
              1RM
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </v-sheet>
      <v-row dense>
        <v-col cols="12">
          <FlipCard dark>
            <template v-slot:back>
              <v-card-subtitle
                :style="
                  isPhone
                    ? 'overflow-y: auto; height:220px;'
                    : 'overflow-y: auto; height:350px;'
                "
                :class="textSizeXSmall + ' silver--text'"
              >
                {{ exercise.description }}
              </v-card-subtitle>
            </template>
            <template v-slot:front>
              <v-img
                :max-height="imageSize"
                :src="exercise.image"
                :alt="exercise.name"
                :title="exercise.name"
                contain
                class="elevation-1 paper"
                gradient="to bottom left, rgba(216,27,96,.1), rgb(31, 44, 76, .3)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="accent lighten-4"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
          </FlipCard>
        </v-col>
      </v-row>
      <v-card-actions class="pt-0 px-0 mt-n10">
        <v-spacer />
        <BaseActionButton
          icon="mdi-content-duplicate"
          text
          color="silver"
          :large="!isPhone"
          v-if="canEditExercise"
          v-bind="$attrs"
          label="Duplicate"
          title="Duplicate Exercise"
          @clickedThis="startDuplicate"
        />
      </v-card-actions>
      <v-card-title :class="textSize + ' paper--text pa-0'">
        <v-divider />
        <span class="mx-2">
          Instructions
        </span>
        <v-divider />
      </v-card-title>
      <v-sheet color="transparent charcoalTile" class="ma-2 py-2" rounded>
        <div v-if="exercise.instructions != ''">
          <v-card-text class="px-0 mt-n2 silver--text">
            <span v-html="exercise.instructions" :class="textSizeXSmall" />
          </v-card-text>
        </div>
      </v-sheet>
      <v-sheet color="transparent charcoalTile" class="ma-2 py-2" rounded>
        <v-card-title class="px-0 pt-0">
          <v-divider />
          <span class="mx-2 paper--text">
            Muscles & Equipment
          </span>
          <v-divider />
        </v-card-title>
        <template v-if="Array.isArray(primary) && primary.length">
          <v-card-subtitle class="px-0 py-1">
            <h6 class="silver--text">Primary Muscles</h6>
            <span class="silver--text" v-for="p in primary" :key="p.muscleid"
              ><v-icon x-small class="progressActive--text">mdi-blur</v-icon>
              {{ p.name }}
            </span>
          </v-card-subtitle>
        </template>
        <template v-if="Array.isArray(secondary) && secondary.length">
          <v-card-subtitle class="px-0 py-1">
            <h6 class="silver--text">Secondary Muscles</h6>
            <span class="silver--text" v-for="p in secondary" :key="p.muscleid"
              ><v-icon x-small class="progressActive--text">mdi-blur</v-icon>
              {{ p.name }}
            </span>
          </v-card-subtitle>
        </template>
        <template v-if="Array.isArray(stabilizers) && stabilizers.length">
          <v-card-subtitle class="px-0 py-1">
            <h6 class="silver--text">Stabilizing Muscles</h6>
            <span
              class="silver--text"
              v-for="p in stabilizers"
              :key="p.muscleid"
              ><v-icon x-small class="progressActive--text">mdi-blur</v-icon>
              {{ p.name }}
            </span>
          </v-card-subtitle>
        </template>
        <template v-if="Array.isArray(equipment) && equipment.length">
          <v-card-subtitle class="px-0 py-1">
            <h6 class="silver--text">Equipment</h6>
            <span
              class="silver--text"
              v-for="p in equipment"
              :key="p.equipmentid"
            >
              <v-icon x-small class="progressActive--text">mdi-blur</v-icon>
              {{ p.name }}
            </span>
          </v-card-subtitle>
        </template>
      </v-sheet>
      <v-sheet color="transparent charcoalTile" class="ma-2 py-2" rounded>
        <v-card-title class="px-0 pb-0">
          <v-divider />
          <span class="mx-2 paper--text">
            Properties
          </span>
          <v-divider />
        </v-card-title>
        <v-card-actions class="px-1 py-1">
          <BaseTag
            v-if="exercise.public == 'Yes'"
            label="Public"
            icon="mdi-bullhorn"
            :tagsize="tagsize"
          />
          <BaseTag
            v-if="exercise.resistance == 'Yes'"
            label="Resistance"
            icon="mdi-weight-lifter"
            :tagsize="tagsize"
          />
          <BaseTag
            v-if="exercise.bilateral == 'Yes'"
            label="Bilateral"
            icon="mdi-focus-field-horizontal"
            class="mr-2"
            :tagsize="tagsize"
          />
          <BaseTag
            v-if="exercise.bodyweight == 'Yes'"
            label="Bodyweight"
            icon="mdi-yoga"
            :tagsize="tagsize"
          />
          <BaseTag
            v-if="exercise.calisthenics == 'Yes'"
            label="Calisthenics"
            icon="mdi-human-handsup"
            :tagsize="tagsize"
          />
          <BaseTag
            v-if="exercise.cardio == 'Yes'"
            label="Cardio"
            icon="mdi-run-fast"
            class="mr-2"
            :tagsize="tagsize"
          />
        </v-card-actions>
        <v-card-subtitle class="px-0">
          <BaseTag :label="exercise.theforce" :tagsize="tagsize" />
          <BaseTag :label="exercise.mechanics" :tagsize="tagsize" />
          <BaseTag :label="exercise.utility" :tagsize="tagsize" />
        </v-card-subtitle>
      </v-sheet>
      <v-sheet color="transparent charcoalTile" class="ma-2 py-2" rounded>
        <v-card-title :class="textSize + ' pl-0 silver--text pb-0 '">
          <span class="mx-2">Similar Exercises</span>
          <v-divider />
        </v-card-title>
        <v-card-text
          v-for="(ex, index) in similar"
          :key="index"
          :class="textSizeXSmall + ' silver--text link py-1 mb-1'"
        >
          <BaseLinkIcon small icon="mdi-link-variant" />
          {{ ex.name }}
        </v-card-text>
      </v-sheet>
      <BaseSnackBar :show="snackbar" :snack="snackText" />
    </v-card>
    <v-skeleton-loader
      v-else
      type="card-avatar, article, actions"
    ></v-skeleton-loader>
    <v-dialog
      v-model="showDuplicateDialog"
      persistent
      :fullscreen="isPhone"
      :width="dialogWidth"
    >
      <v-card color="charcoal" class="silver--text d-flex flex-column">
        <BaseCloseDialogHeader
          heading="  Duplicate Exercise?"
          @cancel="$emit('cancel')"
        />
        <v-card-text class="charcoalTile">
          <div
            :class="textSize + ' progressActive--text'"
            v-html="exercise.name"
          ></div>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dark
                v-model="exercise.newname"
                ref="name"
                :counter="50"
                label="New Name"
                prepend-icon="mdi-dumbbell"
              />
            </v-col>
          </v-row>
          <v-card-subtitle class="body-1 pt-5 silver--text">
            Update the exercise name above and then click the
            <span class="progressActive--text">Duplicate </span> button.
          </v-card-subtitle>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="silver"
            plain
            @clickedThis="showDuplicateDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            label="Duplicate"
            plain
            icon="mdi-content-duplicate"
            color="paper"
            @clickedThis="duplicateExercise()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'
const FlipCard = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/FlipCard.vue')

export default {
  components: { FlipCard },
  mixins: [util],
  data: () => ({
    similar: [],
    topLifts: [],
    showDuplicateDialog: false,
    show: false,
    loaded: false,
    snackbar: false,
    showTopLifters: false,
    snackText: '',
    rm: null,
    timeout: 2000
  }),
  beforeMount() {
    this.loadSimilar()
    if (this.loggedIn) this.loadDetail()
    this.loadTopLifts()
  },
  methods: {
    loadTopLifts() {
      {
        if (
          this.exercise.maxtest != 'Yes' ||
          this.exercise.type != 'Weight Training'
        ) {
          this.topLifts = []
          this.loaded = true
          return
        }
        let url = '/leaderboard/' + this.exercise.id + '/0/5'
        return axios.get(this.baseURL + url, {}).then(response => {
          if (response.status == 200) {
            this.topLifts = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
      }
    },
    loadSimilar() {
      {
        let url = '/exercises/similar/'

        return axios
          .get(this.baseURL + url + this.exercise.id, {})
          .then(response => {
            if (response.status == 200) {
              this.similar = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    startDuplicate() {
      this.showDuplicateDialog = true
      this.exercise.newname = 'Copy of ' + this.exercise.name
    },
    duplicateExercise() {
      let exerciseid = null
      return axios
        .put(this.baseURL + '/exercises/duplicate', {
          exerciseid: this.exercise.id,
          name: this.exercise.newname
        })
        .then(response => {
          if (response.status == 200) {
            exerciseid = response.data.data['exerciseid']
            this.$router.push({
              path: '/exercises/edit/' + exerciseid
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addFavorite(exerciseid) {
      return axios
        .post(this.baseURL + '/exercises/favorites', {
          exerciseid: exerciseid
        })
        .then(response => {
          if (response.status == 200) {
            this.exercise.is_favorite = 'Yes'
            this.toast(this.exercise.name + ' succesfully added to favorites.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadDetail() {
      return axios
        .get(this.baseURL + '/stats/strength/detail/' + this.exercise.id)
        .then(response => {
          if (response.status == 200) {
            if (response.data.data) this.rm = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    removeFavorite(exerciseid) {
      return axios
        .delete(this.baseURL + '/exercises/favorites/' + exerciseid, {})
        .then(response => {
          if (response.status == 200) {
            this.exercise.is_favorite = 'No'
            this.toast(
              this.exercise.name + ' succesfully removed from favorites.'
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  },

  computed: {
    historyLabel() {
      return this.isPhone ? 'Exercise History' : 'Your Exercise History'
    },
    imageSize() {
      return this.isPhone ? 220 : 350
    },
    isFav() {
      return this.exercise.is_favorite == 'Yes'
    },
    tagsize() {
      return this.isPhone ? 'small' : 'medium'
    },
    canEditExercise() {
      return this.xvy || this.exercise.author == this.userid
    },
    ...appConfig
  },
  props: {
    exercise: {
      type: Object,
      required: true
    },
    primary: {
      type: Array,
      required: false
    },
    secondary: {
      type: Array,
      required: false
    },
    stabilizers: {
      type: Array,
      required: false
    },
    equipment: {
      type: Array,
      required: false
    }
  }
}
</script>
